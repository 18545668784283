<template>
    <div class="ownerEntrust">
       <rxNavBar androidOrIOSFlag="true"></rxNavBar>
<!--        头部-->
        <div class="head">
            <img class="headImg" src="../../../assets/images/head-quotation-mark.png" alt="">
            <strong>我要委托</strong>
        </div>
<!--        内容区-->
        <div class="body">
            <div class="ownerInfo">
                <div :class="ownerName? 'redDot' : 'grayDot'"></div>
                <div class="ownerName">房主姓名</div>
                <div class="vertiacl"></div>
                <input class="inputInfo" v-model="ownerName" type="text" placeholder="请输入房主姓名">
            </div>
            <div class="ownerInfo">
                <div :class="mobile? 'redDot' : 'grayDot'"></div>
                <div class="ownerName">手机号</div>
                <div class="vertiacl"></div>
                <input class="inputInfo"  type="text" placeholder="请输入便于联系的手机号" maxlength="11"
                       @blur="mobileVerify" v-model="mobile" onkeyup="this.value=this.value.replace(/\D/g,'')">
            </div>
            <div class="ownerInfo">
                <div :class="address? 'redDot' : 'grayDot'"></div>
                <div class="ownerName">地址</div>
                <div class="vertiacl"></div>
                <input class="inputInfo" v-model="address" type="text" placeholder="请输入详细地址">
            </div>
            <div class="lastText">示例:孝陵卫街道 22号 跑马花园 5单 202室</div>
        </div>
        <div class="btn">
            <button :class="ownerName&&mobile&&address ? 'redBtn' : 'grayBtn'"
                    :disabled="!ownerName||!mobile||!address" @click="confirm">
                确认
            </button>
        </div>
    </div>
</template>

<script>
    import { NavBar} from 'vant';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {globaluserId, checkAndroid,
        checkIOS, responseUtil} from "../../../libs/rongxunUtil";
    import {saveOwnerEntrust} from "../../../getData/getData";
    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end***********
    export default {
        components: {
            RxNavBar,
            [NavBar .name]:NavBar,
            [rxNavBar .name]:rxNavBar,
        },
        data() {
            return {
                ownerName : '',
                mobile : '',
                address : '',
            }
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        methods : {
            //确认事件确认前再验证手机号
            confirm(){
                var t = /^1[345678][0-9]{9}$/
                var tel = this.mobile
                if(!t.test(tel)){
                    responseUtil.alertMsg(this,'手机号格式不正确')
                }else{
                    let that = this
                    let initData = {}
                    initData.user_id = globaluserId()
                    initData.userName = that.ownerName
                    initData.mobile = that.mobile
                    initData.address = that.address
                    saveOwnerEntrust(initData).then(function (response) {
                        responseUtil.dealResponse(that,response,()=>{
                            console.log(response)
                            that.leftReturn();
                        })
                    })
                }

            },

            leftReturn(){
                //APP传值*******begin*********
                if(checkAndroid()){
                    backToAndroid();
                }else if(checkIOS()){
                    console.log("对ios传参");
                    window.webkit.messageHandlers.closeWindow.postMessage({});
                }
                //APP传值*******end*********
            },

            //手机号input框失去焦点事件，判断手机号格式
            mobileVerify(){
                var t = /^1[345678][0-9]{9}$/
                var tel = this.mobile
                 if(!t.test(tel)){
                    responseUtil.alertMsg(this,'手机号格式不正确')
                }
            }
        }
    }
</script>

<style lang="less" scoped >
    *{
        padding: 0;
        margin: 0;
    }
    //头部
    .head{
        position: relative;
        margin-top: 20px;
        margin-bottom: 30px;
        img{
            height: 80px;
            width: 66px;
        }
        strong{
           position: absolute;
            left:30px;
            top:39px;
            font-size: 28px;
        }
    }
    //中间内容区
    .body{
        .ownerInfo{
            display: flex;
            align-items: center;
            background-color: #ffffff;
            border-radius: 8px;
            width: 92%;
            margin: 10px auto;
            height: 50px;
            .grayDot,.redDot{
                height: 5px;
                width: 5px;
                border-radius: 50%;
                margin-left: 15px;
            }
            .grayDot{
                background-color: gray;
            }
            .redDot{
                background-color: red;
            }
            .ownerName{
                font-size: 15px;
                font-weight: bold;
                margin-left: 10px;
            }
            .vertiacl{
                height:12px;
                border: 0.5px solid #e6e6e6;
                margin-left: 15px;
            }
            .inputInfo{
                width: 60%;
                margin-left: 15px;
                border: none;
                /*margin-bottom: 2px;*/
                font-size: 15px;
            }
            input::placeholder{
                font-size: 15px;
                color: #e6e6e6;
            }
        }
        //信息末尾的示例
        .lastText{
            text-align: right;
            color: #999999;
            font-size:12px ;
            width: 92%;
            margin: 0 auto;
        }
    }
    //最后的按钮
    .btn{
        /*border: 1px solid red ;*/
        width: 92%;
        margin: 70px auto;
        .grayBtn,.redBtn{
            width: 100%;
            height: 45px;
            font-size:16px ;
            color: white;
            line-height: 45px;
            border-radius: 8px;
            border: none;
        }
        .grayBtn{
            background-color: #999999;
        }
        .redBtn{
            background-image: linear-gradient(to right,#FFC274,#FF5D3B);
        }
    }
</style>
